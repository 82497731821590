.app-heading {
  text-transform: uppercase;
  color: #FFFFFF;
}


.form-wrapper {
  height: 100%;
  width: 100%;
  max-width: 600px;
  position: relative;
}

.busy-progress-circular {
  position: absolute;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  background-color: rgba(255, 255, 255, .8);
  display: flex;
  justify-content: center;
  align-items: center;
}